import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'SongVisionCast';

	constructor() {

		// Just moved this from index.html to see if we can capture the messag here to display it in the title field

		window.ccContext = window.cast.framework.CastReceiverContext.getInstance();

		window.ccContext.setLoggerLevel(window.cast.framework.LoggerLevel.DEBUG);

		window.ccContext.addCustomMessageListener('urn:x-cast:com.songvision.text', function (customEvent) {
			console.log("Incomming message: %O", customEvent);
			if (customEvent.data.type == "message") {
				console.log("Incomming message: %O", customEvent);
				this.title = customEvent;
			}
		});

		window.ccContext.addCustomMessageListener('urn:x-cast:com.google.cast.tp.heartbeat', function (customEvent) {
			console.log("Incomming message: %O", customEvent);
		});

		window.ccContext.start();

	}
}
